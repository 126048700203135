import React from 'react'

// import Layout from '@components/Layout'
import Layout from '../components/Layout'
import Image from '../components/Image'
import { Section } from '../components/Layout'
// @ts-ignore
import content from '../../content/index.yaml'

// https://stackoverflow.com/questions/26882177/react-js-inline-style-best-practices
const styles = {
    paddingLeft_0: { paddingLeft: 0 }
}

const IndexPage: React.FC = () => {

    const sections = content.sections
    const images = content.images

    return (
        <Layout head={content.head} carouselImages={content.carousel_images}>
            <div className="background-section"></div>
            {/*row1: bovenaan links foto met toren + rechts tekst bisschop*/}
            <div className="welcome-bishop">
                <div className="row">
                    {/* (!) col grid see https://getbootstrap.com/docs/4.4/layout/grid/
                        - xs: both 12 cols an text first
                        - sm and higher: image first 5 cols, text 7 cols
                     */}
                    <div className="col-sm-7 order-sm-2" style={styles.paddingLeft_0}>
                        <div className="welcome-bishop-box">
                            <Section content={sections[0]}/>
                        </div>
                        <div className="spacer-sm-30"></div>
                    </div>
                    <div className="col-sm-5 order-sm-1">
                        <Image className={'welcome-bishop-img'} image={images[0]}/>
                    </div>
                </div>
            </div>
            <div className="spacer-30"></div>
            {/*row 2: links tweede welkomstwoord (... in de kathedraal) met rechts foto interieur MG-8049-H (ahw binnenkomen i/d kathedraal)*/}
            <div className="row">
                <div className="col-md">
                    <Section content={sections[1]}/>
                    <div className="spacer-10"></div>
                    <Section content={sections[2]}/>
                    <div className="spacer-10"></div>
                    <Section content={sections[3]}/>
                    <div className="spacer-10"></div>
                </div>
                <div className="col-md">
                    <Image image={images[1]}/>
                    <div className="spacer-10"></div>
                </div>
            </div>
        </Layout>
    )
}

export default IndexPage